<header class="header-auth">

    <nav class="main_nav">
        <div class="container">
            <div class="row">
                <div class="col main_nav_col d-flex flex-row align-items-center justify-content-start">
                    <div class="logo_container">
                        <div class="logo">
                            <a [routerLink]="['/']" routerLinkActive="router-link-active" ><img src="../../../../assets/images/logo.png" alt=""  ></a>
                        </div>
                    </div>
                    <div class="main_nav_container ml-auto">
                        <ul class="main_nav_list">
                            <li class="main_nav_item "><a [routerLink]="['/packages']"
                                    routerLinkActive="router-link-active">Packages</a></li>

                            <li class="main_nav_item"><a [routerLink]="['/offers']">offers</a></li>

                            <!-- <li class="main_nav_item"><a [routerLink]="['/destination']"
                                    routerLinkActive="router-link-active">Destinations</a></li> -->

                            <li class="main_nav_item"><a href="#">Destinations</a></li>
                            <li class="main_nav_item"><a [routerLink]="['/agencies']">Agencies</a></li>

                         </ul>
                    </div>
          
                    <!-- <div class="hamburger">
                        <i class="fa fa-bars trans_200"></i>
                    </div> -->
                </div>
            </div>
        </div>
    </nav>

</header>

<div class="menu trans_500" [ngClass]="{active: openmenu}">
    <div class="menu_content d-flex flex-column align-items-center justify-content-center text-center">
        <div class="menu_close_container" (click)="openMenu()">
            <div class="menu_close"></div>
        </div>
        <div class="logo menu_logo">
            <a href="#"><img src="../../../../assets/images/logo.png" alt=""></a>
        </div>
        <ul>
            <li class="menu_item "><a [routerLink]="['/main/packages']"
                    routerLinkActive="router-link-active">Packages</a></li>

            <li class="menu_item"><a [routerLink]="['/main/offers']" routerLinkActive="router-link-active">offers</a>
            </li>
            <li class="menu_item"><a [routerLink]="['/main/destination']"
                    routerLinkActive="router-link-active">Destinations</a></li>
            <li class="menu_item"><a [routerLink]="['/main/agencies']">Agencies</a></li>
            <!-- <li class="menu_item"><a [routerLink]="['/main/contact']" routerLinkActive="router-link-active">contact</a></li> -->

        </ul>
    </div>
</div>
