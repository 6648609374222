import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginEmailComponent } from './Login/login-email/login-email.component';
import { AuthSignUpComponent } from './auth-sign-up/auth-sign-up.component';

const routes: Routes = [

  
    {
      path: 'login',
      component: LoginEmailComponent,

    },
  
    {
      path: 'signup',
      component: AuthSignUpComponent,
    
    },
  
   
  ];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
