<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#31124B"
  type="ball-scale-multiple"
>
</ngx-spinner>
<router-outlet></router-outlet>

<div class="mobile-menu-footer">
    <div [routerLink]="['/']" routerLinkActive="router-link-active" ><i class="fa fa-home" aria-hidden="true"></i></div>
    <div [routerLink]="['/packages']"><i class="fa fa-plane" aria-hidden="true"></i>
    </div>
    <div ><i class="fa fa-compass" aria-hidden="true"></i>
    </div>
    <div [routerLink]="['/agencies']"><i class="fa fa-user" aria-hidden="true"></i>
    </div>
    <!-- <div><i class="fa fa-home" aria-hidden="true"></i></div> -->
  </div>