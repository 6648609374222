   <!--Sign Up Form Start -->
   <div id="sign_up" class="popup_parent_form lity-hide">
    <div >
        <h3 class="underline">Sign Up</h3>
        <hr/>
        <div class="lityform">
            <input type="text" class="form-control fname" id="fname" value="" placeholder="First Name">
            <input type="text" class="form-control lname" id="lname" value="" placeholder="Last Name">
            <hr/>
            <input type="text" class="form-control mb-2" id="cellnumber" value="" placeholder="Cell Number">
            <input type="email" class="form-control" id="email" value="" placeholder="Email">
            <hr/>
            <button class="sub_btn" type="submit" value="Submit">Submit</button>
            
        </div>
    </div>
</div>
<!--Sign Up Form End -->