import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthSignUpComponent } from '../../auth-sign-up/auth-sign-up.component';
import { LoginMainComponent } from '../../Login/login-main/login-main.component';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
    user: SocialUser;
    loggedIn: boolean;
    isMenuShow: boolean;
    IsGoogle = false;
    IsFb = false;
  haveError=false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: SocialAuthService,
    private customAuthService: AuthService) {}

  ngOnInit(): void {
    this.haveError = false;
        this.authService.authState.subscribe((user) => {

          console.log(user);
        if(user.provider === "GOOGLE"){
          this.user = user;
          this.signUpGoogle(user);
          this.loggedIn = (user != null);
        }
        if(user.provider === "FACEBOOK"){
          this.user = user;
          this.signUpFb( this.user);
          this.loggedIn = (user != null);
        }
      });
  }

  open(){
     this.modalService.dismissAll();
     this.isMenuShow = false;
    this.router.navigate(['signup']);

 
    // const modalRef = this.modalService.open(AuthSignUpComponent)
  }
  openLogin(){
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(LoginMainComponent)

  }
  close(){
    this.modalService.dismissAll();

  }

  signInWithGoogle(): void {
    this.haveError = false;
    // console.log(123);
    // this.IsGoogle = true;
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    try {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    } catch (e) {
      console.log(e);
    }
  }

  signInWithFb(): void {
    this.haveError = false;
    // console.log(123);
    // this.IsGoogle = true;
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    try {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    } catch (e) {
      console.log(e);
    }
  }

  signUpGoogle(user){
    this.haveError = false;
    let data: any;
    data = {
            userID :user.email,
            googleId: user.idToken,
            firstname: user.firstName,
            lastname: user.lastName};
    this.customAuthService.createPassengerGoogle(data)
    .subscribe(res => {
      console.log(res);
      this.haveError = false;
      localStorage.setItem('users', JSON.stringify(res));
      // this.modalService.dismissAll();
      window.location.reload();
    },
    error => {
      this.haveError = true;
    });
  }


  signUpFb(user){
    this.haveError = false;
    let data: any;
    data = {
            userID :user.email,
            facebookId: user.id,
            firstname: user.firstName,
            lastname: user.lastName};
    this.customAuthService.createPassengerFb(data)
    .subscribe(res => {
      console.log(res);
      this.haveError = false;
      localStorage.setItem('users', JSON.stringify(res));
      this.modalService.dismissAll();
      window.location.reload();
    },
    error => {
      this.haveError = true;
    });
  }
}
