import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['../sign-in/sign-in.component.css']
})
export class SignUpFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
