import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(private http: HttpClient) { }

  public getData(route: string) {
    return this.http.get(this.createCompleteRoute('secureApi/' + route, environment.urlAddress));
  }

  public getDataById(route: string, Id) {
    return this.http.get(this.createCompleteRouteId(Id, 'secureApi/' + route, environment.urlAddress));
  }

  public getDataByPost(route: string, body) {
    return this.http.post(this.createCompleteRoute('secureApi/' + route, environment.urlAddress), body, this.generateHeaders());
  }

  public create(route: string, body) {
    return this.http.post(this.createCompleteRoute('secureApi/' + route, environment.urlAddress), body, this.generateHeaders());
  }

  public update(route: string, body){
    return this.http.put(this.createCompleteRoute('secureApi/' + route, environment.urlAddress), body, this.generateHeaders());
  }

  public delete(route: string){
    return this.http.delete(this.createCompleteRoute('secureApi/' + route, environment.urlAddress));
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }
  private createCompleteRouteId(Id: string, route: string, envAddress: string) {
    return `${envAddress}/${route}/${Id}`;
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
  }
}
