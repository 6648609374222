import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateSuffixPipe } from './pipe/dateSuffix.pipe';
import { SlugifyPipe } from './pipe/slugify.pipe';

const SHARED_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  CommonModule
];

@NgModule({
  declarations: [
    DateSuffixPipe,
    SlugifyPipe
  ],
  imports: SHARED_MODULES,
  exports: [
    DateSuffixPipe,
    SlugifyPipe,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ]
})
export class SharedModule {}
