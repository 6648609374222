   <!--Sign In Form Start -->
   <div id="sign_in" class="popup_parent_form lity-hide">
    <div >
        <h3 class="underline">Sign In</h3>
        <hr/>
        <div class="lityform">
            <input type="email" class="form-control fname" id="fname" value="" placeholder="Username / Email Address">
            <input type="text" class="form-control lname" id="lname" value="" placeholder="Password Name">
            
            <p class="form_links"><a href="#">Forgot Password </a> | <a href="#">Help </a></p>
            <p class="form_links1"><a href="#" class="create_acc">Create Account</a></p>
            <button class="sub_btn" type="submit" value="Submit">Submit</button>
            
        </div>
    </div>
</div>
<!--Sign In Form End -->