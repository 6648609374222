import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpComponent } from '../../signup/sign-up/sign-up.component';
import { LoginEmailComponent } from '../login-email/login-email.component';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-main',
  templateUrl: './login-main.component.html',
  styleUrls: ['./login-main.component.css']
})
export class LoginMainComponent implements OnInit {
  user: SocialUser;
  loggedIn: boolean;
  IsGoogle = false;
  IsFb = false;
  haveError = false;

  constructor(
    private modalService: NgbModal,
    private authService: SocialAuthService,
    private router: Router,
    private customAuthService: AuthService) {}

  ngOnInit(): void {
 
        this.authService.authState.subscribe((user) => {
          console.log(user);
        if(user.provider === "GOOGLE"){
          this.user = user;
          this.loginGoogle(user.email, user.idToken);
          this.loggedIn = (user != null);
        }
        if(user.provider === "FACEBOOK"){
          this.user = user;
          this.loginFacebook(user.email, user.id);
          this.loggedIn = (user != null);
        }
      });
  }


  open() {
   
    this.modalService.dismissAll();
        // const modalRef = this.modalService.open(LoginEmailComponent)
    this.router.navigate(['auth/login']);

  }
  signUPOPeN(){
    this.modalService.dismissAll();
        const modalRef = this.modalService.open(SignUpComponent)

  }
  close(){
    this.modalService.dismissAll();

  }


  refreshToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }


  signInWithGoogle(): void {

    // console.log(123);
    // this.IsGoogle = true;
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    try {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    } catch (e) {
      console.log(e);
    }
  }

  loginGoogle(email, googleId){
    this.haveError = false;
    let data: any;
    data = {
            userID :email,
            googleId :googleId};
    this.customAuthService.loginPassengerGoogle(data)
    .subscribe(res => {
      console.log(res);
      localStorage.setItem('users', JSON.stringify(res));
     // this.authenticationService.login();
       this.haveError = false;
      // this.haveSucces = true;
     // this.reloadCurrentRoute();
     // this.reloadComponent();
      this.modalService.dismissAll();
      window.location.reload();
    },
    error => {
      this.haveError = true;
    });
  }

  loginFacebook(email, fbId){
    this.haveError = false;
    let data: any;
    data = {
            userID :email,
            facebookId :fbId};
    this.customAuthService.loginPassengerFb(data)
    .subscribe(res => {
      console.log(res);
      localStorage.setItem('users', JSON.stringify(res));
     // this.authenticationService.login();
       this.haveError = false;
      // this.haveSucces = true;
     // this.reloadCurrentRoute();
     // this.reloadComponent();
      this.modalService.dismissAll();
      window.location.reload();
    },
    error => {
      this.haveError = true;
    });
  }

  signInWithFB(): void {
    try {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    } catch (e) {
      console.log(e);
    }
  }
}
