import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignUpComponent } from '../signup/sign-up/sign-up.component';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from "../../shared/services/must-match.validator"
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
@Component({
  selector: 'app-auth-sign-up',
  templateUrl: './auth-sign-up.component.html',
  styleUrls: ['./auth-sign-up.component.scss']
})
export class AuthSignUpComponent implements OnInit {

  signupFor: FormGroup;
  submitted = false;
  showMsg = false;
  haveError = false;
  haveSucces = false;
  user: SocialUser;
  loggedIn: boolean;
  HistroyRoute: any;

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,

    private socialauthService: SocialAuthService,

    private router: Router) {
    this.HistroyRoute = this.router.getCurrentNavigation().extras.state
    console.log(' this.HistroyRoute', this.HistroyRoute);
  }

  ngOnInit() {
    localStorage.removeItem('users');
    this.socialauthService.authState.subscribe((user) => {
      console.log(user);
      if (user.provider === "GOOGLE") {
        this.user = user;
        this.signUpGoogle(this.user);
        this.loggedIn = (user != null);
      }
      if (user.provider === "FACEBOOK") {
        this.user = user;
        this.signUpFb(user);
        this.loggedIn = (user != null);
      }
    });
    this.signupFor = this.formBuilder.group({
      fullname: ['', Validators.required],
      userID: ['', [Validators.required, Validators.email]],
      cell: ['', [Validators.required, Validators.minLength(11), Validators.pattern("^[0-9_]*$")]],
      pwd: ['', Validators.required],
      cpwd: ['', Validators.required]
    },
      {
        validator: MustMatch('pwd', 'cpwd')
      }
    );
  }

  open() {
  }

  openForm() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(SignUpComponent)
  }

  openMain() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(SignUpComponent)
  }

  close() {
    this.modalService.dismissAll();
  }

  // convenience getter for easy access to form fields
  get f() { return this.signupFor.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.signupFor.invalid) {
      return;
    } else {
      let data: any;

      let dato: any;
      data = {
        firstName: this.f.fullname.value,
        userID: this.f.userID.value, cell: this.f.cell.value,
        pwd: this.f.pwd.value
      };
      dato = { userID: data.userID, pwd: data.pwd };
      console.log(data)
      this.authService.createPassenger(data)
        .subscribe(res => {
          // console.log(res);
          localStorage.setItem('users', JSON.stringify(res));
          // this.authenticationService.login();
          this.haveError = false;
          this.haveSucces = true;
          //this.reloadCurrentRoute();
          // this.reloadComponent();

          // window.location.reload();
          // this.signupFor.reset();
          this.router.navigate(['/'])

          // this.modalService.dismissAll();
        });

    }

  }

  onReset() {
    this.submitted = false;
    this.signupFor.reset();
  }

  reloadComponent() {
    let currentUrl = this.route.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  reloadCurrentRoute() {
    let currentUrl = this.route.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  signUpGoogle(user) {
    this.haveError = false;
    let data: any;
    data = {
      userID: user.email,
      googleId: user.idToken,
      firstname: user.firstName,
      lastname: user.lastName
    };
    this.authService.createPassengerGoogle(data)
      .subscribe(res => {
        console.log(res);
        this.haveError = false;
        localStorage.setItem('users', JSON.stringify(res));
        this.modalService.dismissAll();
        window.location.reload();
      },
        error => {
          this.haveError = true;
        });
  }

  async signInWithGoogle() {
    this.haveError = false;
    try {
      await this.socialauthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
        Object.keys(x).forEach(function (key) {
          if (key == 'firstName') {
            var value = 'firstname';
            x[value] = x[key];
            delete x[key];
          }
        });

        let data: any;
        data = {
          userID: x.email
        };
        this.authService.loginPassengerGoogle(data)
          .subscribe(res => {
            console.log(res);
            localStorage.setItem('users', JSON.stringify(res));
            if (this.HistroyRoute != undefined) {
              this.reloadCurrentRoute();
              this.reloadComponent();
            } else {
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.router.navigate(['/'])
            }
          });

        // localStorage.setItem('users', JSON.stringify(x));

      });

    } catch (e) {
      console.log(e);
    }
  }

  signInWithFb(): void {
    this.haveError = false;
    // console.log(123);
    // this.IsGoogle = true;
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    try {
      this.socialauthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
        //   Object.keys(x).forEach(function(key){
        //     if(key == 'firstName'){
        //       var value = 'firstname';
        //       x[value] = x[key];
        //       delete x[key];
        //     }
        // });
        console.log(x)




      });
    } catch (e) {
      console.log(e);
    }
  }
  gotoSignIn() {
    this.router.navigate(['auth/login']);
  }
  signUpFb(user) {
    this.haveError = false;
    let data: any;
    data = {
      userID: user.email,
      facebookId: user.id,
      firstname: user.firstName,
      lastname: user.lastName
    };

    this.authService.createPassengerFb(data)
      .subscribe(res => {
        console.log(res);
        this.haveError = false;
        localStorage.setItem('users', JSON.stringify(res));
        this.modalService.dismissAll();
        window.location.reload();
      },
        error => {
          this.haveError = true;
        });
  }

}





