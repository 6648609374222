import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AuthenticationService } from 'src/app/main/authentication.service';
import { LoginMainComponent } from '../login-main/login-main.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.css']
})
export class LoginEmailComponent implements OnInit {
  subscriberForm: FormGroup;
  submitted = false;
  showMsg = false;
  haveError = false;
  haveSucces = false;
  HistroyRoute: any;

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private socialauthService: SocialAuthService,
  ) {
    this.HistroyRoute = this.router.getCurrentNavigation().extras.state
    console.log(' this.HistroyRoute', this.HistroyRoute);
  }


  ngOnInit() {
    localStorage.removeItem('users');
    this.subscriberForm = this.formBuilder.group({
      pwd: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  openMain() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(LoginMainComponent)

  }
  close() {
    this.modalService.dismissAll();

  }

  // convenience getter for easy access to form fields
  get f() { return this.subscriberForm.controls; }

  onSubmit() {
    this.submitted = true;

    this.haveError = false;
    // stop here if form is invalid
    if (this.subscriberForm.invalid) {
      this.haveError = true
      return;
    }

    let data: any;
    data = {
      userID: this.f.email.value,
      pwd: this.f.pwd.value
    };
    this.authService.loginPassenger(data)
      .subscribe(res => {
        if (res) {
          localStorage.setItem('users', JSON.stringify(res));
          this.haveError = false;
          this.haveSucces = true;
          if (this.HistroyRoute != undefined) {
            this.reloadCurrentRoute();
            this.reloadComponent();
          } else {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/'])
          }
        }

      },
        error => {
          this.haveError = true;
        });



  }

  onReset() {
    this.submitted = false;
    this.subscriberForm.reset();
  }

  reloadComponent() {
    // let currentUrl = this.route["_routerState"].snapshot.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.HistroyRoute]);
  }

  reloadCurrentRoute() {
    // let currentUrl = this.route["_routerState"].snapshot.url;
    console.log('--->reload route', this.route["_routerState"].snapshot.url)
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    this.router.navigate([this.HistroyRoute]);
    // });
  }
   
  async signInWithGoogle() {

    this.haveError = false;
    try {
      await this.socialauthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
        Object.keys(x).forEach(function(key){
          if(key == 'firstName'){
            var value = 'firstname';
            x[value] = x[key];
            delete x[key];
          }
      });
  
      let data: any;
      data = {
              userID :x.email
             };
      this.authService.loginPassengerGoogle(data)
      .subscribe(res => {
        console.log(res);
        localStorage.setItem('users', JSON.stringify(res));
        if (this.HistroyRoute != undefined) {
          this.reloadCurrentRoute();
          this.reloadComponent();
        } else {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/'])
        }
      });
     
        // localStorage.setItem('users', JSON.stringify(x));
      
      });
  
    } catch (e) {
      console.log(e);
    }
  }

  signInWithFb(): void {
    this.haveError = false;
    try {
      this.socialauthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    } catch (e) {
      console.log(e);
    }
  }
  gotoSignup() {
    this.router.navigate(['auth/signup']);
  }

}
