import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './signup/sign-up/sign-up.component';
import { DateSuffixPipe } from '../shared/pipe/dateSuffix.pipe';
import { SharedModule } from '../shared/shared.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

import { SlugifyPipe } from '../shared/pipe/slugify.pipe';
import { LoginEmailComponent } from './Login/login-email/login-email.component';
import { AuthSignUpComponent } from './auth-sign-up/auth-sign-up.component';
import { LayoutComponent } from '../main/layout/layout/layout.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AuthRoutingModule } from './auth.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationComponent } from './authentication.component';
import { LoginMainComponent } from './Login/login-main/login-main.component';
import { HeaderAuthComponent } from './layout/header/header.component';


@NgModule({
  declarations: [LoginEmailComponent,
    AuthSignUpComponent,
    SignUpFormComponent,
    LoginMainComponent,
    SignInComponent, SignUpComponent,
    HeaderAuthComponent
   ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    AutocompleteLibModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AuthModule { }
