import { Component, Injectable, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/shared/models/user.model';
import { AuthenticationService } from 'src/app/main/authentication.service';
import { LoginMainComponent } from '../../../authentication/Login/login-main/login-main.component';
import { SignInComponent } from '../../sign-in/sign-in.component';
import { SignUpComponent } from '../../signup/sign-up/sign-up.component';
import { Router } from '@angular/router';
// import { CompingSoonComponent } from '../../popup/compingSoon/compingSoon.component';

@Component({
  selector: 'app-auth-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderAuthComponent implements OnInit {
  loggedInUser:any;
  users: User;
  openmenu = false
  dropdown: boolean;
constructor(private modalService: NgbModal,

  private router: Router
  //private authenticationService: AuthenticationService
  ) {
    //authenticationService.getLoggedInName.subscribe(name => this.changeName(name));
  }

  private changeName(name: string): void {
    this.loggedInUser = name;
}

  ngOnInit(): void {
    this.users = JSON.parse(localStorage.getItem('users'));
    if(this.users){
      this.loggedInUser = this.users.firstname;
    }
  }

  openMenu(){
    this.openmenu =  !this.openmenu
  }


  openDropdown(){
    if(this.loggedInUser){
    this.dropdown =  !this.dropdown
    }
    else{
      const modalRef = this.modalService.open(LoginMainComponent);
    }
  }

  // openComingSoon() {
  //   const modalRef = this.modalService.open(CompingSoonComponent)
  // }

  open() {
    const modalRef = this.modalService.open(LoginMainComponent)
  }

  openSignup() {
    const modalRef = this.modalService.open(SignUpComponent)
  }

  logout(){
    localStorage.removeItem('users');
    //this.authenticationService.logout();
      this.reloadCurrentRoute();
      this.reloadComponent();
  }

  reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}
}
