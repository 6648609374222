
<div class="super_container">
  <app-auth-header></app-auth-header>
  <div class="Sign-up-page">
    <div class="bg-2">
  
      <div class="row">
        <div class="col-md-4">
          <div class="Travel-to-your-dreams">
            <span > Travel to your dreams </span>
            <label class="Dream-having-the-best-travel-of-your-life">
              Dream having the best travel of your life
            </label>
            <div class="Rectangle-445">
              <span class="Explore-Plans"> Explore Plans </span>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="Rectangle-1">
            <label class="Lets-Travel"> Let's Travel </label>
            <p
              class="
                Before-moving-further-we-need-give-some-information-to-make-your-trip-memorizing
              "
            >
              Before moving further, we need give some information to make your
              trip memorizing
            </p>
            <form [formGroup]="signupFor" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="formGroupExampleInput" class="labeltxt"
                  >Fullname</label
                >
                <input
                  type="text"
                  class="form-control inputBox"
                  id="formGroupExampleInput"
                  formControlName="fullname"
                  [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"
                />
                <div
                  *ngIf="submitted && f.fullname.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.fullname.errors.required">
                    Full Name is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1" class="labeltxt"
                  >Email Address</label
                >
                <input
                  type="email"
                  formControlName="userID"
                  class="form-control inputBox"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  [ngClass]="{ 'is-invalid': submitted && f.userID.errors }"
                />
                <div
                  *ngIf="submitted && f.userID.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.userID.errors.required">Email is required</div>
                  <div *ngIf="f.userID.errors.email">
                    Email must be a valid email address
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="labeltxt"
                  >Phone Number</label
                >
                <input
                  type="text"
                  class="form-control inputBox"
                  id="exampleInputPassword1"
                  formControlName="cell"
                  [ngClass]="{ 'is-invalid': submitted && f.cell.errors }"
                />
                <div *ngIf="submitted && f.cell.errors" class="invalid-feedback">
                  <div *ngIf="f.cell.errors.required">Cell is required</div>
                  <div *ngIf="f.cell.errors.pattern">Invalid Phone</div>
                  <div *ngIf="f.cell.errors.minlength">
                    Minimum 11 digits are allowed.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="labeltxt"
                  >Password</label
                >
                <input
                  formControlName="pwd"
                  type="password"
                  class="form-control inputBox"
                  id="exampleInputPassword1"
                  [ngClass]="{ 'is-invalid': submitted && f.pwd.errors }"
                />
                <div *ngIf="submitted && f.pwd.errors" class="invalid-feedback">
                  <div *ngIf="f.pwd.errors.required">Password is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="labeltxt"
                  >Confirm Password</label
                >
                <input
                  type="password"
                  class="form-control inputBox"
                  id="exampleInputPassword1"
                  formControlName="cpwd"
                  [ngClass]="{ 'is-invalid': submitted && f.cpwd.errors }"
                />
                <div *ngIf="submitted && f.cpwd.errors" class="invalid-feedback">
                  <div *ngIf="f.cpwd.errors.required">Password is required</div>
                  <div *ngIf="f.cpwd.errors.mustMatch">
                    Passwords must match
                 </div>
                </div>
              </div>
  
              <button type="submit" class="btn signup btn-block">Submit</button>
  
              <div class="wrapper">
                <div class="Line-1"></div>
                <span class="Or-Continue-with"> Or Continue with</span>
                <div class="Line-2"></div>
              </div>
              <div class="btn btn-block google-btn" (click)= "signInWithGoogle()">
                <img
                  src="../../../assets/images/group-41.png"
                  srcset="
                    ../../../assets/images/group-41@2x.png 2x,
                    ../../../assets/images/group-41@3x.png 3x
                  "
                  class="google-logo"
                />
                <span class="Google" > Google </span>
              </div>
              <!-- <div class="wrapper">
                <div class="google-btn" (click)= "signInWithGoogle()">
                  <img
                    src="../../../assets/images/group-41.png"
                    srcset="
                      ../../../assets/images/group-41@2x.png 2x,
                      ../../../assets/images/group-41@3x.png 3x
                    "
                    class="google-logo"
                  />
                  <span class="Google" > Google </span>
                </div>
                <div class="facebook-btn" (click)= signInWithFb()>
                  <img
                    src="../../../assets/images/group-43.png"
                    srcset="
                      ../../../assets/images/group-43@2x.png 2x,
                      ../../../assets/images/group-43@3x.png 3x
                    "
                    class="fb-logo"
                  />
                  <span class="Facebook"> Facebook </span>
                </div>
              </div> -->
              <!-- <div class="btn btn-block facebook-btn" (click)= signInWithFb()>
                <img
                  src="../../../assets/images/group-43.png"
                  srcset="
                    ../../../assets/images/group-43@2x.png 2x,
                    ../../../assets/images/group-43@3x.png 3x
                  "
                  class="fb-logo"
                />
                <span class="Facebook"> Facebook </span>
              </div> -->
     
              <span class="Or-youre-already-traveler-Sign-In-Now">
                Or you're already traveler?
                <a class="text-style-1" (click)="gotoSignIn()">Sign In Now</a>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


