import { Injectable } from '@angular/core';
import { RepositoryService } from '../repository/repository.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

constructor(private repository: RepositoryService) { }

  public createPassenger(body) {
    return this.repository.getDataByPost('createpassenger', body);
  }

  public loginPassenger(body) {
    return this.repository.getDataByPost('loginpassenger', body);
  }
  public createPassengerGoogle(body) {
    return this.repository.getDataByPost('createpassengergoogle', body);
  }

  public loginPassengerGoogle(body) {
    return this.repository.getDataByPost('loginpassengergoogle', body);
  }

  public createPassengerFb(body) {
    return this.repository.getDataByPost('createpassengerfb', body);
  }

  public loginPassengerFb(body) {
    return this.repository.getDataByPost('loginpassengerfb', body);
  }
}
