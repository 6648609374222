
<div class="super_container">
  <app-auth-header></app-auth-header>
  <div class="Login-Page">
    <div class="bg-2">
      <!-- <img
        src="../../../assets/images/web-img/group-48.png"
        srcset="
          ../../../assets/images/web-img/group-48@2x.png 2x,
          ../../../assets/images/web-img/group-48@3x.png 3x
        "
        class="Group-47"
      /> -->
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="Travel-to-your-dreams">
            <span > Travel to your dreams </span>
            <label class="Dream-having-the-best-travel-of-your-life">
              Dream having the best travel of your life
            </label>
            <div class="Rectangle-445">
              <span class="Explore-Plans"> Explore Plans </span>
            </div>
          </div>
       
        
          
        </div>
        <div class="col-md-8 col-sm-12">
          <div class="Rectangle-1">
            <label class="Lets-Travel"> Let's Travel </label>
            <p
              class="
                Before-moving-further-we-need-give-some-information-to-make-your-trip-memorizing
              "
            >
              Before moving further, we need give some information to make your
              trip memorizing
            </p>
            <form [formGroup]="subscriberForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="exampleInputEmail1" class="labeltxt"
                  >Email Address</label
                >
                <input
                  type="email"
                  class="form-control inputBox"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
          
                />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
              </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="labeltxt"
                  >Password</label
                >
                <input
                  type="password"
                  class="form-control inputBox"
                  id="exampleInputPassword1"
                  type="password"
                  formControlName="pwd"
                  [ngClass]="{ 'is-invalid': submitted && f.pwd.errors }"
          
                />
                <div *ngIf="submitted && f.pwd.errors" class="invalid-feedback">
                  <div *ngIf="f.pwd.errors.required">Password is required</div>
              </div>
              </div>
  
              <button type="submit" class="btn signup btn-block">Submit</button>
  
              <div class="wrapper">
                <div class="Line-1"></div>
                <span class="Or-Continue-with"> Or Continue with</span>
                <div class="Line-2"></div>
              </div>
              <div class="btn btn-block google-btn" (click)= "signInWithGoogle()">
                <img
                  src="../../../assets/images/group-41.png"
                  srcset="
                    ../../../assets/images/group-41@2x.png 2x,
                    ../../../assets/images/group-41@3x.png 3x
                  "
                  class="google-logo"
                />
                <span class="Google"> Google </span>
              </div>
              <!-- <div class="wrapper">
              <div class="google-btn" (click)= "signInWithGoogle()">
                <img
                  src="../../../assets/images/group-41.png"
                  srcset="
                    ../../../assets/images/group-41@2x.png 2x,
                    ../../../assets/images/group-41@3x.png 3x
                  "
                  class="google-logo"
                />
                <span class="Google"> Google </span>
              </div>
              <div class="facebook-btn" (click)="signInWithFb()">
                <img
                  src="../../../assets/images/group-43.png"
                  srcset="../../../assets/images/group-43@2x.png 2x, ../../../assets/images/group-43@3x.png 3x"
                  class="fb-logo"
                />
                <span class="Facebook"> Facebook </span>
              </div>
              </div> -->
              <span class="Or-dont-have-any-traveler-account-Make-New-One">
                Or don't have any traveler account?
                  <a class="text-style-1" (click)="gotoSignup()">Make New One</a>
                </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
